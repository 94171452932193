<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Authentication Preferences</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="px-10 py-5 pt-10 mt-5">
                        <p v-if="!error">Please wait...</p>
                        <p v-if="error" class="red--text">Service unavailable. Please try again later.</p>
                    </v-card>
                    <!-- TODO: for Unicorn Springs iframe... -->
                    <!-- <v-row justify="center" v-show="loginWithLoginShield" class="ma-0 pt-5" style="width: 100%;">
                        <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
                    </v-row> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapState /* , mapGetters */ } from 'vuex';

export default {
    data() {
        return {
            error: false,
        };
    },
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
    },
    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    methods: {
        init() {
            // authentication preferences are managed in LoginFront, so we just redirect the
            // user to that site
            // TODO: check if user is authenticated, if not then have them login first, then
            // return here to be redirected
            this.redirectToLoginFront();
        },
        async redirectToLoginFront() {
            try {
                this.$store.commit('loading', { getPrefsRedirect: true });
                const { redirect } = await this.$client.main().authn.prefsRedirect();
                if (redirect) {
                    // use replace so that when user taps 'back' button from there, they won't
                    // end up being redirected again to where they just wanted to come back from
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        // TODO: also show the link on the page for user to click
                        window.location.href = redirect;
                    }
                    return;
                }
                this.error = true;
            } catch (err) {
                if (err.response?.status === 401) {
                    // user must already be authenticated to access authentication settings
                    this.$router.push({ name: 'login', query: { next: '/preferences/authn' } });
                    return;
                }
                console.error('init failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { getPrefsRedirect: true });
            }
        },
    },
    mounted() {
        if (this.isAuthenticatedReady) {
            this.init();
        }
    },
};
</script>
